<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>마이페이지</h1>
              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="이름"
                  v-model="user.username"
                />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="기존 비밀번호"
                  v-model="user.password"
                />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="새 비밀번호"
                  v-model="user.newPassword"
                />
              </b-input-group>
              <b-button
                @click.prevent="$router.go(-1)"
                variant="secondary"
                block
                >이전</b-button
              >
              <b-button @click.prevent="updateUser" variant="primary" block
                >수정</b-button
              >
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import { mapGetters } from "vuex";

export default {
  name: "User",
  created() {},
  mounted() {
    document.getElementsByClassName("app-body")[0].classList.remove("bg-white");
    this.getUser();
  },
  computed: {
    ...mapGetters({
      currentUser: "getUser"
    })
  },
  data() {
    return {
      user: {
        password: null,
        newPassword: null
      }
    };
  },
  watch: { user() {} },
  methods: {
    async getUser() {
      const response = await UserService.getUser(this.currentUser.id);
      if (response.status == 200) {
        this.user = response.data.user;
      }
    },
    async updateUser() {
      const response = await UserService.updateUser(this.user.id, {
        username: this.user.username,
        password: this.user.password,
        newPassword: this.user.newPassword
      });
      if (response.status == 200) {
        alert("수정 완료");
        this.user.password = null;
        this.user.newPassword = null;
      }
    }
  }
};
</script>
